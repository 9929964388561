<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
    <ayl-main title="基本信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="contents">
          <span>用户账号：</span>
          <span>{{ form.username ||'- -' }}</span>
        </div>
		<div class="contents">
		  <span>用户名称：</span>
		  <span>{{ form.nickName ||'- -' }}</span>
		</div>
		<div class="contents">
		  <span>联系方式：</span>
		  <span>{{ form.phone ||'- -' }}</span>
		</div>
		<div class="contents">
		  <span>状态：</span>
		  <span>{{ form.status ||'- -' }}</span>
		</div>
		<div class="contents">
		  <span>所属公司：</span>
		  <span>{{ form.companyName ||'- -' }}</span>
		</div>
		<div class="contents">
		  <span>用户级别：</span>
		  <span>{{ form.userLevel ||'- -' }}</span>
		</div>
		<div class="contents">
		  <span>禁止修改密码：</span>
		  <span>{{ form.allowChangePassword ||'- -' }}</span>
		</div>
		<div style="width: 100%">
		  <span style="color: #262626;">角色分配：</span>
		  <span>{{ stropList(form.roleNameList) ||'- -' }}</span>
		</div>
      </div>
    </ayl-main>
    <ayl-main title="其他信息(选填)" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="contents">
            <span>邮箱：</span>
            <span>{{ form.email ||'- -' }}</span>
        </div>
		<div style="width: 100%;">
		    <span style="color: #262626;">备注：</span>
		    <span>{{ form.remark ||'- -' }}</span>
		</div>
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="$router.push('/system-manage/userManage')">返回</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: "系统管理", path: ""},
          {name: "人员管理", path: "/system-manage/userManage"},
          {name: "用户详情", path: ""},
        ],
        form: {
          username: null,
          nickName: null,
          phone: null,
          status: null,
          companyName: null,
          userLevel: null,
          allowChangePassword: null,
          roleNameList: null,
          email: null,
          remark: null,
        },
      }
    },
    methods:{
      submitInsurance(){
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            // await this.$api.AddEmployee(this.staff)
          } catch (e){}
          // this.submitLoading = false
        })
      },
      stropList(e){
        let str = ''
        str = ( e || []).join('、')
        return str
      }
    },
	async mounted(){
		this.form = await this.$api.getUserGet({
			userIdentity: this.$route.query.userIdentity
		})
	}
  }
</script>
<style lang='sass' scoped>

</style>
